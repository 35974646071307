import { useEffect, useContext } from "react"
import { navigate } from "gatsby"
import AvailabilityContext from "gatsby-singita-theme/src/context/AvailabilityContext"

const PlanYourTripPage = () => {
  const { setShowAvailability } = useContext(AvailabilityContext)

  useEffect(() => {
    setShowAvailability(true)
    navigate("/")
  }, [])

  return null
}

export default PlanYourTripPage
